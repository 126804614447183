import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

/**
 * Icons
 * @https://material.io/tools/icons/
 */
import MenuIcon from '@material-ui/icons/Menu';
import CodeIcon from '@material-ui/icons/Code';
const HomeIcon = () => {
  return (
    <svg
      fill="#757575"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path fill="none" d="M0 0h24v24H0V0z" />
      <path d="M12 5.69l5 4.5V18h-2v-6H9v6H7v-7.81l5-4.5M12 3L2 12h3v8h6v-6h2v6h6v-8h3L12 3z" />
    </svg>
  );
};

const BookmarksIcon = () => {
  return (
    <svg
      fill="#757575"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path fill="none" d="M0 0h24v24H0V0z" />
      <path d="M15 7v12.97l-4.21-1.81-.79-.34-.79.34L5 19.97V7h10m4-6H8.99C7.89 1 7 1.9 7 3h10c1.1 0 2 .9 2 2v13l2 1V3c0-1.1-.9-2-2-2zm-4 4H5c-1.1 0-2 .9-2 2v16l7-3 7 3V7c0-1.1-.9-2-2-2z" />
    </svg>
  );
};

const toggleStyle = {
  position: `absolute`,
  top: `0px`,
  right: `0px`,
  width: `40px`,
  height: `60px`,
  padding: 0,
  borderRadius: `100%`,
  background: `none`,
  color: `#fff`,
};

const styles = {
  list: {
    width: 250,
  },
};

class DrawerMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      top: false,
      left: false,
      bottom: false,
      right: false,
    };
    this.toggleDrawer = this.toggleDrawer.bind(this);
  }

  toggleDrawer(side, open) {
    // return () => this.setState({ [side]: open });
    // 上記だとIE11でエラーが出るのでrightに固定
    return () => this.setState({ right: open });
  }

  render() {
    const { classes, navi } = this.props;

    // ホームを除外.
    const page_navi = navi.filter((item) => {
      return item.path !== '/'
    });

    const sideList = (
      <div className={classes.list}>
        <List>
          <ListItem button component={Link} to={`/`}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={`ホーム`} />
          </ListItem>
        </List>
        <Divider />
        <List>
          {page_navi.map(($navi, i) => (
            <ListItem button key={i} component={Link} to={$navi.path}>
              <ListItemIcon>
                {(() => {
                  if ($navi.path === '/entries') {
                    return <BookmarksIcon />;
                  }
                  if ($navi.path === '/about') {
                    return <CodeIcon />;
                  }
                })()}
              </ListItemIcon>
              <ListItemText primary={$navi.name} />
            </ListItem>
          ))}
        </List>
      </div>
    );

    return (
      <div className={`site-toggle__navigation`}>
        <Button onClick={this.toggleDrawer('right', true)} style={toggleStyle}>
          <MenuIcon />
        </Button>
        <Drawer
          anchor="right"
          open={this.state.right}
          onClose={this.toggleDrawer('right', false)}
        >
          <div
            tabIndex={0}
            role="button"
            onClick={this.toggleDrawer('right', false)}
            onKeyDown={this.toggleDrawer('right', false)}
          >
            {sideList}
          </div>
        </Drawer>
      </div>
    );
  }
}

DrawerMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  navi: PropTypes.array.isRequired,
};

export default withStyles(styles)(DrawerMenu);
