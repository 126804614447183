import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabsRoot: {
    //borderBottom: '1px solid #e8e8e8',
  },
  tabsIndicator: {
    backgroundColor: '#0073aa',
  },
  tabRoot: {
    textTransform: 'initial',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#283A4C',
      opacity: 1,
      background: '#fafafa',
    },
    '&$tabSelected': {
      color: '#0073aa',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#0073aa',
      background: 'none',
    },
  },
  tabSelected: {},
  typography: {
    padding: theme.spacing(3),
  },
});

class TabMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cat_ID: 0,
    };

    // Thisをバインド必須
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event, value) {
    this.setState({
      cat_ID: value,
    });
    this.props.history.push(this.props.navi[value].link);
  }

  render() {
    // {}で直接取得
    const { classes, navi } = this.props;
    const { cat_ID } = this.state;

    return (
      <div className={`category-navigation`}>
        <div className={classes.root}>
          <div className={`category-menu`}>
            <div className={`category-inner`}>
              <Tabs
                value={cat_ID}
                onChange={this.handleChange}
                classes={{
                  root: classes.tabsRoot,
                  indicator: classes.tabsIndicator,
                }}
              >
                {navi.map(($cat, i) => (
                  <Tab
                    disableRipple
                    classes={{
                      root: classes.tabRoot,
                      selected: classes.tabSelected,
                    }}
                    label={$cat.title}
                    key={i}
                  />
                ))}
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

TabMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  navi: PropTypes.array.isRequired,
};

export default withRouter(withStyles(styles)(TabMenu));
