import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Helmet from 'react-helmet';
import { have_posts } from '../../commons/Utils';

class Single extends Component {
  render() {
    const { entry } = this.props;

    const linkBackPage = () => this.props.history.goBack();

    if (have_posts(entry)) {
      return (
        <article className="entry">
          <div className="entry-navi">
            <button onClick={linkBackPage} className={`entry-navi__prev`}>
              ← 戻る
            </button>
          </div>
          <div className="entry__row">
            <div className="entry__row--col">
              <Helmet title={entry.title} />
              <this.DOMFeatured src={entry.attachment} alt={entry.title} />
            </div>
            <div className="entry__row--col">
              <div className="entry__sticky">
                <this.DOMHeader title={entry.title} preview={entry.link} />
                <div className="entry-content">
                  <h2>特徴</h2>
                  <p>{entry.content}</p>
                </div>
                <this.DOMFooter tags={entry.tag} />
              </div>
            </div>
          </div>
        </article>
      );
    } else {
      return <Redirect to="/entries" />;
    }
  }

  DOMFeatured(props) {
    return (
      <div id="featured" className="entry-featured">
        <img src={props.src[0]} alt={props.alt} />
      </div>
    );
  }

  DOMHeader(props) {
    return (
      <div className="entry-header">
        {props.preview && (
          <div className="entry-header__navi">
            <a
              className={`button entry-header__navi-preview`}
              href={props.preview}
              target="_blank"
              rel="noreferrer noopener"
            >
              プレビュー
            </a>
          </div>
        )}
        <h1 className="entry-header__title">{props.title}</h1>
      </div>
    );
  }

  DOMFooter(props) {
    return (
      <div className="entry-footer">
        <div className="entry-footer__tags">
          <h3>タグ：</h3>
          <ul>
            {props.tags.map(($tag, i) => {
              return <li key={i}>{$tag}</li>;
            })}
          </ul>
        </div>
      </div>
    );
  }

  static mapStateToProps(state, props) {
    const { id } = props.match.params;
    let single = state.entry.filter((val) => val.id === id);
    if (single[0]) {
      return {
        entry: single[0],
      };
    }
    return {
      entry: [],
    };
  }
}
export default withRouter(connect(Single.mapStateToProps)(Single));
