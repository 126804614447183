import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    marginTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(3),
    height: '100%',
    boxShadow: `none`,
    background: `none`,
  },
});

const GridUI = (props) => {
  const { classes, children } = props;

  return (
    <div className={classes.root}>
      <Grid container spacing={0} alignItems="stretch">
        {children.map((child, i) => (
          <Grid key={i} item sm>
            <Paper className={classes.paper}>{child}</Paper>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

GridUI.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.arrayOf(PropTypes.node),
};

export default withStyles(styles)(GridUI);
