import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Helmet from 'react-helmet';
import Thumbnail from '../template-parts/Thumbnail';
import { have_posts } from '../../commons/Utils';

class Category extends Component {
  render() {
    const { entries, page_title } = this.props;

    if (have_posts(entries)) {
      return (
        <article className="entries">
          <Helmet title={page_title} />
          {entries.map((entry) => {
            return (
              <section className="entry" id={`post-${entry.id}`} key={entry.id}>
                <Thumbnail
                  id={entry.id}
                  attachment={entry.attachment}
                  cat={entry.cat}
                />
              </section>
            );
          })}
        </article>
      );
    } else {
      return <Redirect to="/entries" />;
    }
  }

  static mapStateToProps(state, props) {
    const { cat } = props.match.params;
    let taxonomy = [];
    let result;

    state.entry.forEach((query) => {
      result = query.cat.filter((val) => val === cat);
      if (result[0]) {
        taxonomy.push(query);
      }
    });
    return {
      entries: taxonomy,
      page_title: 'テーマリスト - ' + cat,
    };
  }
}
export default connect(Category.mapStateToProps)(Category);
