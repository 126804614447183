import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const CustomTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    background: 'none',
    boxShadow: 'none',
  },
  table: {
    minWidth: 700,
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
});

let id = 0;
function createData(name, calories, fat, carbs) {
  id += 1;
  return { id, name, calories, fat, carbs };
}

const rows = [
  createData(
    '制作ページ総数',
    '1ページ',
    '1ページ',
    '8ページ'
  ),
  createData('ルーム表示枠', '1枠', '3枠', '無制限'),
  createData(
    'ルーム画像点数',
    '各1部屋に最大3枚',
    '各1部屋に最大3枚',
    '無制限'
  ),
  createData('サービス紹介枠', '3枠', '5枠', '無制限'),
  createData('ブログ機能', '◎', '◎', '◎'),
  createData('ヘッダー画像', '最大3枚', '最大3枚', '最大3枚'),
  createData('更新サポート', '2回/月', '2回/月', '2回/月'),
];

const CustomizedTable = (props) => {
  const { classes } = props;

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <CustomTableCell></CustomTableCell>
            <CustomTableCell align="center">
              Lightプラン<div style={{ color: `#F7C85E` }}>5,000円/月</div>
            </CustomTableCell>
            <CustomTableCell align="center">
              Basicプラン<div style={{ color: `#F7C85E` }}>10,000円/月</div>
            </CustomTableCell>
            <CustomTableCell align="center">
              Premiumプラン<div style={{ color: `#F7C85E` }}>20,000円/月</div>
            </CustomTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow className={classes.row} key={row.id}>
              <CustomTableCell component="th" scope="row">
                {row.name}
              </CustomTableCell>
              <CustomTableCell align="center">{row.calories}</CustomTableCell>
              <CustomTableCell align="center">{row.fat}</CustomTableCell>
              <CustomTableCell align="center">{row.carbs}</CustomTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

CustomizedTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomizedTable);
