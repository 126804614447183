import React from 'react';
import Helmet from 'react-helmet';
import TableUI from '../commons/TableUI';
import GridUI from '../commons/GridUI';
import { aboutImg1, aboutImg2, aboutImg3 } from '../assets/images';

const About = () => (
  <article className="section">
    <header className="page__header">
      <div className="wrap">
        <div className="page__header--title">
          <Helmet title="ホームページに必要なことAll-in-One" />
          <h1>
            ホームページに必要なこと
            <br className="sm" />
            All-in-One
          </h1>
          <p>
            高品質多機能な
            <br className="sm" />
            ホテル業界専門WordPressテーマ
          </p>
        </div>
      </div>
      <span className="arrow"></span>
    </header>
    <section className="section__area-odd about-us">
      <div className="wrap">
        <GridUI>
          <div key="1">
            <img src={aboutImg1} alt="独自ドメインの管理" />
            <h2 className="about-us__title">独自ドメインの管理</h2>
            <p>
              独自ドメインとは世界にたった一つだけのインターネットの住所にあたるものです。ブラウザのアドレスバーに表示されている
              <code>https://</code>
              から始まるURLになります。ホームページを公開してアクセスするにはこのドメインが必要です。通常はドメイン管理会社との別途契約が必要ですが、弊社制作のホームページでは公開時に１つお好きなドメイン（.comまたは.net）をお選びいただけます。ホームページ契約期間中はドメイン取得費用や更新費といった追加料金や手続きは必要ございません。
            </p>
          </div>
          <div key="2">
            <img src={aboutImg2} alt="独自ドメインの管理" />
            <h2 className="about-us__title">サーバー管理と維持</h2>
            <p>
              サーバーとはインターネットに接続したコンピューターで、ホームページのデータを保存し世界に公開する機材のことです。そのため、ホームページを公開するには、取得したドメインの設定やシステムの専門的な技術や、定期的なメンテナンスが必要ですが、弊社のホームページは取得した独自ドメインの設定から、サーバーの管理まで契約期間中サービスの一貫として行なっております。そのため、安心してホームページをご利用いただけます。
            </p>
          </div>
          <div key="3">
            <img src={aboutImg3} alt="独自ドメインの管理" />
            <h2 className="about-us__title">デザインとシステム</h2>
            <p>
              新しくオリジナルのホームページをオーダーメイドで作る場合、デザイナーによる洗練されたデザイン、ホームページとしての形にするコーディング、メールフォームや更新機能のプログラミングなどなど、多くの専門技能と時間を費やして作る必要がございます。弊社では予めデザイナーによる高品質なデザインテーマに多機能なプログラムをプリセットしたホームページをご用意しておりますので、お申し込みからホームページ掲載情報が揃い次第、すぐに公開が可能です。
            </p>
          </div>
        </GridUI>
      </div>
    </section>
    <section
      className="section__area-odd"
      style={{ borderTop: 'solid 1px #ddd' }}
    >
      <div className="wrap" style={{ marginBottom: '0px' }}>
        <header className="section__header">
          <h2 className="section__header--title">料金プラン</h2>
        </header>
        <div className="section__content">
          <TableUI />
          <div style={{ color: `#676767`, fontSize: `0.825em` }}>
            <ul>
              <li>表示料金は税抜価格となります。</li>
              <li>
                更新サポートは画像の差し替えやテキストの変更のみとさせていただきます。ブログの投稿や編集はホテル様でも管理画面から行えます。
              </li>
              <li>
                トップページの画像等の表示数についてはテーマのデザインによって変わります。
              </li>
              <li>
                制作ページ総数はトップページを含みます。管理画面から投稿されたブログの記事数は含みません。
              </li>
              <li>
                個人情報保護法への対応により、公開するホームページには「個人情報保護方針について」または「プライバシーポリシー」のページがございます。個人情報保護方針のページは制作ページ数には含みません。
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </article>
);

export default About;
