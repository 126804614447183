import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Helmet from 'react-helmet';
import Site from './site-config.json';
import ScrollToTop from './commons/ScrollToTop';

// Pages include
import Home from './components/Home';
import About from './components/About';

// Redux Pages
import EntriesIndex from './containers/entries/Index';

const Routes = () => (
  <ScrollToTop>
    <Helmet
      htmlAttributes={{ lang: 'ja' }}
      title={Site.description}
      titleTemplate={Site.title + ' - %s'}
    />
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/about" component={About} />
      <EntriesIndex />
      <Route render={() => <Redirect to="/entries" />} />
    </Switch>
  </ScrollToTop>
);

export default Routes;
