import 'whatwg-fetch' /* IE11 fetch Polifill */
import 'promise-polyfill/src/polyfill' /* IE11 fetch Polifill */

export const getEntriesError = status => ({
	type: 'GET_ENTRIES_ERROR',
	hasError: status
})

export const loadEntries = status => ({
	type: 'LOAD_ENTRIES',
	isLoading: status
})

export const fetchEntriesSuccess = entry => ({
	type: 'FETCH_ENTRIES_SUCCESS',
	entry
})

export const fetchEntries = url => {
	return (dispatch) => {
		dispatch(loadEntries(true));

		fetch(url).then((response) => {
			if(!response.ok) {
				throw Error(response.statusText);
			}
			dispatch(loadEntries(false));

			return response;
		})
		.then((response) => response.json())
		.then((entry) => dispatch(fetchEntriesSuccess(entry)))
		.catch(() => dispatch(getEntriesError(true)));
	}
}
