// 記事があるかの判定
export const have_posts = (obj) => {
  return Object.keys(obj).length;
};

// ページスラッグのStyleClass
export const class_body = (props) => {
  const location = props;
  let pathName = '';
  let depth = '';

  if (location.pathname === '/') {
    pathName = 'page-home';
  } else {
    pathName = 'page-' + location.pathname.split('/')[1];

    if (location.pathname.split('/')[2]) {
      depth = location.pathname.split('/')[2];

      if (Number(depth)) {
        pathName = pathName + '-single';
      } else {
        pathName = 'page-' + depth;
      }
    }
  }
  return pathName;
};
