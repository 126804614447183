import React from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import TabMenu from '../template-parts/TabMenu';
import Archives from './Archives';
import Category from './Category';
import Single from './Single';

const TabList = [
  {
    title: 'All',
    link: '/entries',
  },
  {
    title: 'Lightプラン',
    link: '/entries/category/Light',
  },
  {
    title: 'Basicプラン',
    link: '/entries/category/Basic',
  },
  {
    title: 'Premiumプラン',
    link: '/entries/category/Premium',
  },
];

const Index = (props) => {
  const { location } = props;

  return (
    <div>
      <TabMenu navi={TabList} />
      <div className="wrap">
        <TransitionGroup>
          <CSSTransition
            key={location.pathname}
            classNames={`fade`}
            timeout={300}
          >
            <Switch>
              <Route exact path="/entries/category/:cat" component={Category} />
              <Route exact path="/entries/:id" component={Single} />
              <Route exact path="/entries" component={Archives} />
              <Route render={() => <Redirect to="/" />} />
            </Switch>
          </CSSTransition>
        </TransitionGroup>
      </div>
    </div>
  );
};

export default withRouter(Index);
