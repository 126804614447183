export const getEntriesError = (state = false, action) => {
	switch (action.type) {
		case 'GET_ENTRIES_ERROR':
		return action.hasError;
	default:
		return state;
	}
}

export const loadEntries = (state = false, action) => {
	switch (action.type) {
		case 'LOAD_ENTRIES':
		return action.isLoading;
	default:
		return state;
	}
}

export const entry = (state = [], action) => {
	switch (action.type) {
		case 'FETCH_ENTRIES_SUCCESS':
		return action.entry;
	default:
		return state;
	}
}