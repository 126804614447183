import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import GridUI from '../../../commons/GridUI';

class SectionRecent extends Component {
  render() {
    const { entry } = this.props;

    if (entry) {
      return (
        <section className="section__area-odd">
          <div className="wrap">
            <div className="section__content">
              <GridUI>
                <div id={`post-${entry.id}`}>
                  <this.DOMThumbnail
                    id={entry.id}
                    attachment={entry.attachment}
                    title={entry.title}
                  />
                </div>
                <div>
                  <h2>なぜ、WordPressなのでしょうか？</h2>
                  <p>
                    弊社制作のホームページの基盤に採用しているコンテンツ管理システムの「WordPres」は、全世界のWebサイトの33%のシェアを誇ります。コンテンツ管理システムを導入すると専用の管理画面からホームページの更新や編集ができるようになります。
                  </p>
                  <p>
                    何百人もの開発者がWordPressを改良し、最初のバージョンが2003年にリリースされて以来。非常に人気があり、使いやすく、安定して安全です。そんな、信頼と実績のあるWordPress搭載のホームページを、ホテル様のニーズに合うようにカスタマイズされた専用テーマでご提供いたします。
                  </p>
                  <this.DOMPageLink />
                </div>
              </GridUI>
            </div>
          </div>
        </section>
      );
    } else {
      return false;
    }
  }

  DOMPageLink() {
    return (
      <div style={{ paddingTop: `1em` }}>
        <Link to={`/entries`} className={`button`}>
          テーマリスト
        </Link>
      </div>
    );
  }

  DOMThumbnail(props) {
    return (
      <div className="recent-image">
        <img
          src={
            Array.isArray(props.attachment)
              ? props.attachment[0]
              : props.attachment
          }
          alt={props.title}
        />
      </div>
    );
  }

  static mapStateToProps(state, props) {
    return {
      entry: state.entry[2],
    };
  }
}
export default connect(SectionRecent.mapStateToProps)(SectionRecent);
