import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const Thumbnail = (props) => {
  const entry = props;

  return (
    <Link className={`entry-thumbnail`} to={`/entries/${entry.id}`}>
      <img src={entry.attachment[0]} alt={entry.title} />
      <div className="entry-thumbnail__cat">
        <ul>
          {entry.cat.map(($cat, i) => {
            return <li key={i}>{$cat}</li>;
          })}
        </ul>
      </div>
    </Link>
  );
};

Thumbnail.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  cat: PropTypes.array.isRequired,
  attachment: PropTypes.array,
};

export default Thumbnail;
