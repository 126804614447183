import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Helmet from 'react-helmet';
import Thumbnail from '../template-parts/Thumbnail';
import { have_posts } from '../../commons/Utils';

class Archives extends Component {
  render() {
    const { entries, page_title } = this.props;

    if (have_posts(entries)) {
      return (
        <article className="entries">
          <Helmet title={page_title} />
          {entries.map((entry) => {
            return (
              <section className="entry" id={`post-${entry.id}`} key={entry.id}>
                <Thumbnail
                  id={entry.id}
                  attachment={entry.attachment}
                  cat={entry.cat}
                />
              </section>
            );
          })}
        </article>
      );
    } else {
      return <Redirect to="/entries" />;
    }
  }

  static mapStateToProps(state, props) {
    return {
      entries: state.entry,
      page_title: 'テーマリスト',
    };
  }
}
export default connect(Archives.mapStateToProps)(Archives);
