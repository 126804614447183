import React from 'react';
import { HashRouter as Router, withRouter } from 'react-router-dom';
import Routes from './routes';
import { Provider } from 'react-redux';
import configureStore from './redux/store/store';
import PreLoader from './redux/PreLoader';
import { class_body } from './commons/Utils';
import Header from './components/Header';
import Footer from './components/Footer';

const store = configureStore();

const App = withRouter(({ location }) => {
  return (
    <PreLoader>
      <div id="page" className={`site ${class_body(location)}`}>
        <Header />
        <main id="main" className="site-main site-content">
          <Routes />
        </main>
        <Footer />
      </div>
    </PreLoader>
  );
});

const root = () => (
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>
);
export default root;
