import React from 'react';

const Footer = () => (
  <footer className="site-footer">
    <div className="wrap">
      <div className="site-footer__area">
        <div className="site-info">
          © {new Date().getFullYear()},{` `}
          <a
            href="https://pallet-net.co.jp/"
            target="_blank"
            rel="noopener noreferrer"
          >
            株式会社パレット
          </a>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
