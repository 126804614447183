import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

const GlobalMenu = (props) => {
  const { navi } = props;

  return (
    <nav className="main-navigation">
      <ul className="main-menu">
        {navi.map(($nav, i) => (
          <li key={i} className="menu-item">
            <NavLink to={$nav.path} activeClassName="current" exact={true}>
              {$nav.name}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
};

GlobalMenu.propTypes = {
  navi: PropTypes.array.isRequired,
};

export default GlobalMenu;
