import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Site from '../site-config.json';
import { logo, logoHP, BackImage, ItemImage } from '../assets/images';
import DrawerMenu from './template-parts/navigation/DrawerMenu';
import GlobalMenu from './template-parts/navigation/GlobalMenu';

const NaviList = [
  { path: '/', name: 'ホーム' },
  { path: '/entries', name: 'テーマリスト' },
  { path: '/about', name: '制作について' },
];

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: [
        {
          path: '/',
          name: 'ホーム',
        },
      ],
    };
  }

  componentDidMount() {
    this.setState(() => ({
      menu: NaviList,
    }));
  }

  locate() {
    /* withRouterで現在のパスを取得 */
    return this.props.location.pathname;
  }

  backStyle() {
    if (this.locate() === '/') {
      return {
        backgroundImage: `url(${BackImage})`,
        backgroundPosition: `center bottom`,
        backgroundRepeat: `no-repeat`,
      };
    } else {
      return {
        backgroundImage: `url(${BackImage})`,
        backgroundPosition: `center top`,
        backgroundRepeat: `no-repeat`,
      };
    }
  }

  render() {
    return (
      <header className="site-header" style={this.backStyle()}>
        <DrawerMenu navi={this.state.menu} />
        <div className="wrap">
          <div className="site-header__area">
            <div className="site-branding">
              <div className="site-logo">
                <Link to={`/`}>
                  <img src={logo} alt={Site.title} />
                </Link>
              </div>
              <this.SiteTitle current={this.locate()} />
            </div>
            <GlobalMenu navi={this.state.menu} />
            <this.Description current={this.locate()} />
          </div>
          {(() => {
            if (this.locate() === '/') {
              return (
                <div className="site-header__item">
                  <img src={ItemImage} alt={Site.title} />
                </div>
              );
            }
          })()}
        </div>
      </header>
    );
  }

  SiteTitle(props) {
    if (props.current === '/') {
      return (
        <h1 className="site-title">
          <Link to="/">
            <img src={logoHP} alt={Site.title} style={{ height: '30px' }} />
          </Link>
          {/*<span className="site-title__sub">{Site.description}</span>*/}
        </h1>
      );
    } else {
      return (
        <div className="site-title">
          <Link to="/">
            <img src={logoHP} alt={Site.title} style={{ height: '30px' }} />
          </Link>
          {/*<span className="site-title__sub">{Site.description}</span>*/}
        </div>
      );
    }
  }

  Description(props) {
    if (props.current === '/') {
      return (
        <div className="site-description">
          <h2>ホームページ制作</h2>
          <p>世界No1シェアの管理システム「WordPress」搭載</p>
        </div>
      );
    } else {
      return false;
    }
  }
}
export default withRouter(Header);
