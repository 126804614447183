import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchEntries } from './actions/actions';
import Site from '../site-config.json';

class PreLoader extends Component {

	componentDidMount() {
		if( process.env.NODE_ENV === 'development' ) {
			this.props.fetchData( Site.REST_API.development );
		} else {
			this.props.fetchData( Site.REST_API.production );			
		}
	}

	render() {		
		if (this.props.hasError) {
			return (<div style={LoaderStyle}> REST Error </div>)
		}
		if (this.props.isLoading) {
			return (<div style={LoaderStyle}>{console.log(`Loading...`)}</div>)
		}
		return this.props.children;
	}
}

const LoaderStyle = {
	display: `flex`,
	flexDirection: `column`,
	justifyContent: `center`,
	alignItems: `center`,
	width: `100vw`,
	height: `100vh`,
	fontSize: `2rem`,
	fontWeight: `bold`,
	color: `#bbb`
};

PreLoader.propTypes = {
	fetchData: PropTypes.func.isRequired,
	entry: PropTypes.array.isRequired,
	hasError: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
	entry: state.entry,
	hasError: state.getEntriesError,
	isLoading: state.loadEntries
});

const mapDispatchToProps = dispatch => ({
	fetchData: (url) => dispatch(fetchEntries(url))
});

export default connect(mapStateToProps, mapDispatchToProps)(PreLoader);