import React from 'react';
import GridUI from '../commons/GridUI';
import SectionRecent from './template-parts/home/SectionRecent';
import SectionGoogleMap from './template-parts/home/SectionGoogleMap';
import { eventImg, smartImg, searchImg } from '../assets/images';

const titleStyle = {
  textAlign: `center`,
  fontSize: `1.325em`,
};

const imgStyle = {
  borderBottom: `solid 1px #ccc`,
};

const Home = () => (
  <article className="section">
    <section className="section__area-odd">
      <div className="wrap">
        <header className="section__header" style={{ marginTop: `0px` }}>
          <h2 className="section__header--title">
            集客につながるホームページ作りませんか？
          </h2>
        </header>
        <div className="section__content">
          <GridUI>
            <div>
              <img
                src={eventImg}
                alt="お店の宣伝やイベントの告知"
                style={imgStyle}
              />
              <h3 style={titleStyle}>宣伝やイベントの告知</h3>
              <p>
                世界で最も有名で多機能なブログ機能を備えています。ブログを更新するのに専門的な知識はいりません。ただ、文字を打つだけの簡単操作でWordのようにホームページを更新できます。
              </p>
            </div>
            <div>
              <img
                src={smartImg}
                alt="スマートフォンにも標準対応"
                style={imgStyle}
              />
              <h3 style={titleStyle}>スマートフォン標準対応</h3>
              <p>
                スマートフォンやタブレットのカメラで写真を撮影、そのままホームページへ掲載ということもお手軽にスムーズに行えます。シーンを選ばず、24時間365日いつでも投稿でき、どこからでも更新できます。
              </p>
            </div>
            <div>
              <img
                src={searchImg}
                alt="検索サイトへの登録もお任せ"
                style={imgStyle}
              />
              <h3 style={titleStyle}>検索サイト登録もお任せ</h3>
              <p>
                ホームページの更新内容はGoogleなどの検索エンジンへ自動送信されます。Googleのガイドラインに則った高水準のSEOを備えていますので、ブログに掲載した内容を辿って、集客アップに繋がります。
              </p>
            </div>
          </GridUI>
        </div>
      </div>
    </section>
    <SectionGoogleMap />
    <SectionRecent />
  </article>
);

export default Home;
