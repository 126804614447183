import React from 'react';
import { googlemapBG, googlemapImg } from '../../../assets/images';

const mapStyle = {
  backgroundImage: `url(${googlemapBG})`,
  backgroundPosition: `center bottom`,
  minHeight: `300px`,
  backgroundRepeat: `no-repeat`,
  borderTop: `solid 1px #eee`,
  borderBottom: `solid 1px #eee`,
};

const SectionGoogleMap = () => (
  <section className="section__area-even googlemap" style={mapStyle}>
    <div className="wrap">
      <div className="section__content">
        <div className="googlemap__row">
          <div className="googlemap__row--col">
            <h2 className="googlemap__title">Google Mapで店舗をアピール！</h2>
            <p>
              店舗案内に便利なGoogleMapの登録がまだお済みでなければ登録サポートいたします。地図検索の時にもウェブサイトのアドレスが載るので、店舗情報と一緒にお客様をご案内できるようになります。もちろん新しいホームページにもGoogleMapを配置致します。
            </p>
          </div>
          <div className="googlemap__row--col">
            <img src={googlemapImg} alt="Google Mapで店舗をアピール" />
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default SectionGoogleMap;
